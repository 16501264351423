import Moment from 'moment'
import es from '@/i18n/es.json'

Moment.locale('es')

export function DateFormat (value, utc = false) {
  const parse = (utc) ? Moment.utc(value) : Moment(value)
  return parse.format(es.commons['date-format'])
}

export function TimeFormat (value, utc = false) {
  const parse = (utc) ? Moment.utc(value) : Moment(value)
  return parse.format(es.commons['time-format'])
}

export function DatetimeFormat (value, utc = false) {
  const parse = (utc) ? Moment.utc(value) : Moment(value)
  return parse.format(es.commons['datetime-format'])
}
